<template>
	<div class="emb-gadget-wrap">
		<div class="gadget-content section-gap">
			<v-container grid-list-xl py-0>
				<v-layout row wrap>
					<v-flex xs12 sm12 md12 lg12 xl12>
						<v-row dense>
							<v-col
								class="d-flex align-items-stretch"
								v-for="(item, i) in items"
								:key="i"
								cols="12"
								xl="3"
								lg="6"
								md="12"
								sm="12"
							>
								<v-card hover dark color="secondary">
									<v-img
										:src="item.image"
										class="white--text align-end"
										gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
										height="200px"
									>
										<p class="text-center mt-3 ma-1 white--text">
											{{ item.name }}
										</p>
									</v-img>
									
									<p class="text-center caption mt-3">
										{{ (item.description) ? item.description : 'NO TIENE DESCRIPCIÓN' }}
									</p>

									<v-card-actions>
										<v-btn 
											v-if="ifItemExistInWishlist(item)" 
											@click="addItemToWishlist(item)" 
											class="px-0"
											icon
										>
											<v-icon class="black--text">mdi-heart</v-icon>
										</v-btn>
										<v-btn 
											v-else 
											@click="addItemToWishlist(item)" 
											class="px-0"
											icon 
										>
											<v-icon class="grey--text">mdi-heart</v-icon>
										</v-btn>
										<v-spacer></v-spacer>												
										<h6 class="mt-2">
											<strong><emb-currency-sign></emb-currency-sign> {{ item.delivery_price }}</strong>
										</h6>
									</v-card-actions>
									<v-card-actions>
										<el-button
											type="primary"
											size="medium"
											class="btn-block"
											round
											@click="addProductToCart(item)"
										>
											Ordenar
										</el-button>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-flex>
				</v-layout>
				
				<v-layout align-center justify-end class="mt-4">
					<v-flex xs12 sm12 md12 lg12 xl12>
						<div class="layout justify-end" v-if="cart && cart.length > 0">
							<emb-sidebar-panel></emb-sidebar-panel>
							<v-btn class="mx-3" color="primary" @click="payment">
								<v-icon dark left small>payment</v-icon>
								Proceder con el pago
							</v-btn>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
		<emb-location-dialog
            :showLocationDialog.sync="showLocationDialog"
            :isRouteMenu="true"
            :lat="lat"
            :lng="lng"
			:draggable="true"
			:autoAdd="true"
			:item="item"
			@add="addProductToCart"
        >
        </emb-location-dialog>
	</div>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		data() {
			return {
				resource: 'promotions',
				showLocationDialog: false,
				categories: [],
				items: [],
				item: null,
				lat: null,
				lng: null,
			}
		},
		computed: {
			...mapGetters(["cart","wishlist", 'orderData', 'location', 'loggedIn'])
		},
		async created() {
			await this.getPromotions()
		},
		methods: {
			...mapActions({
                addNotification: 'addNotification'
			}),
			async getPromotions() {
				await this.$http.get(`/${this.resource}/records`).then(( response) => {
					this.items = response.data
				})
			},
			addProductToCart(item) {
				if (this.location) {
					if (!this.ifItemExistInCart(item)) {
						setTimeout(() => { 
							this.$store.dispatch("addProductToCart", item);
						}, 100);
	
						this.addNotification({
							show: true,
							type: 'success',
							message: 'El Producto ha sido agregado al carrito satisfactoriamente'
						})
					} else {
						this.addNotification({
							show: true,
							type: 'error',
							message: 'El Producto ya ha sido agregado al carrito'
						})
					}
				} else {
					this.item = item
					this.showLocationDialog = true
				}
			},
			/**
			 * method for checking if item exists in cart
			*/
			ifItemExistInCart(result) {
				let exists = false;
				if (this.cart) {
					for (let item of this.cart) {
						if (item.item_id == result.id) {
							exists = true;
						}
					}
				}
				return exists;
			},
			// this method is use to add a product in wishlist
			addItemToWishlist(data) {
				if (this.ifItemExistInWishlist(data)) {
					
					this.addNotification({
						show: true,
						type: 'error',
						message: 'Producto existe en la lista de deseos.'
					})

				} else {
					this.addNotification({
						show: true,
						type: 'success',
						message: 'Producto agregado a la lista de deseos'
					})

					setTimeout(() => {
						this.$store.dispatch("addItemToWishlist", data);
					}, 100);
				}
			},
			/**
			 * This Function Is use to check weather the product exist in the wishlist
			 * Return boolean
			*/
			ifItemExistInWishlist(result) {
				let exists = false;
				for (let item of this.wishlist) {
					if (item.id == result.id) {
						exists = true;
					}
				}
				return exists;
			},
			onCalculateItemTotal(item) {
				return this.$store.dispatch('calculateItemTotal', item)
			},
			payment() {
				if (this.loggedIn) {
				this.$router.push({name: 'payment'})
				} else {
				this.$router.push({name: 'login'})  
				}
			}
		}
	}
</script>